:root {
  --red: #ea3333;
  --green: #04C157;
  --yellow: #FEAF07;
  --orange: #F37939;
  --blue: #4682B4;
  --turquoise: #00CED1;
  --softPink: #FFB6C1;
  --pink: #ef0f97;
  --violet: #8600b3;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--red);
  color: #fff;
  font-size: 16px;
  min-height: 100vh;
  padding: 0 20px 40px;
}

.theme--red,
.theme--red .header__headline {
  background-color: var(--red);
}

.theme--green,
.theme--green .header__headline {
  background-color: var(--green);
}

.theme--yellow,
.theme--yellow .header__headline {
  background-color: var(--yellow);
}

.theme--orange,
.theme--orange .header__headline {
  background-color: var(--orange);
}

.theme--blue,
.theme--blue .header__headline {
  background-color: var(--blue);
}

.theme--turquoise,
.theme--turquoise .header__headline {
  background-color: var(--turquoise);
}

.theme--softPink,
.theme--softPink .header__headline {
  background-color: var(--softPink);
}

.theme--pink,
.theme--pink .header__headline {
  background-color: var(--pink);
}

.theme--violet,
.theme--violet .header__headline {
  background-color: var(--violet);
}

.header {
  padding: 150px 0 0;
  background-image: radial-gradient(rgba(0, 0, 0, .25) 10%, transparent 10%), radial-gradient(rgba(0, 0, 0, .25) 10%, transparent 10%);
  background-position: 10px 15px, 35px 40px;
  background-size: 50px 50px;
  width: 100%;
  max-width: 550px;
}

footer {
  width: 100%;
  max-width: 550px;
  text-align: right;
  font-size: 12px;
}

.link {
  color: #fff;
  text-decoration: none;
}

.link:hover {
  color: #fff;
  text-decoration: underline;
}

.header__headline {
  margin: 0;
  padding: 20px 30px 10px 5px;
  display: inline-block;
  text-align: left;
  font-size: 22px;
  font-weight: 700;
}

.sounds {
  display: flex;
  flex-wrap: wrap;
  max-width: 550px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sounds__item {
  margin: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sounds__item svg {
  margin-bottom: 15px;
}

.sounds__button {
  background-color: transparent;
  color: #fff;
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 32px;
  height: 32px;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all 250ms ease-in-out, transform 150ms ease;
  box-shadow: 3px 2px 2px 0 rgba(0, 0, 0, 0.15);
  text-indent: -200px;
}

@media (min-width: 1000px) {
  .sounds__button {
    width: 26px;
    height: 26px;
  }
}

.sounds__button:hover,
.sounds__button:focus {
  background: none;
  outline: none;
}

.sounds__button:hover {
  background-color: #fff;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.15);
  width: 30px;
  height: 30px;
  margin: 1px;
}

@media (min-width: 1000px) {
  .sounds__button:hover {
    width: 24px;
    height: 24px;
  }
}

.sounds__icon circle,
.sounds__icon path {
  fill: #fff;
}
